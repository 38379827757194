body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:where(.css-dev-only-do-not-override-wmid1j).contact-table .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-wmid1j).contact-table .ant-table-thead >tr>td{
  background:#f5f5f5  ;
  color: gray;
  font-weight: 700;
}

:where(.css-dev-only-do-not-override-wmid1j).contact-table .ant-table-tbody >tr >th, :where(.css-dev-only-do-not-override-wmid1j).contact-table .ant-table-tbody >tr >td{
  border-bottom: 5px solid #f0f0f0;
  padding: 5px;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 75px;
}
